import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Dashboard from "./components/dashboard";
import CTA from "./components/CTA";
import Teamleader from "./pages/teamleader";

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  const teamleaderRedirectURI =
    process.env.NODE_ENV === "production"
      ? "https://creactiv.ismart.cloud/teamleader"
      : "http://localhost:3000/teamleader";

  return (
    <div className="App">
        <BrowserRouter>
          <Dashboard
            classes={{
              main: isAuthenticated ? "" : "flex flex-col justify-center",
            }}
          >
            {!isLoading && !isAuthenticated && <CTA />}
            {!isLoading && isAuthenticated && (
              <Routes>
                <Route
                  path="/"
                  element={<Teamleader redirectUri={teamleaderRedirectURI} />}
                />
                <Route
                  path="teamleader"
                  element={<Teamleader redirectUri={teamleaderRedirectURI} />}
                />
                <Route
                  path="callback"
                  element={<Navigate replace to="/" />}
                />
              </Routes>
            )}
          </Dashboard>
        </BrowserRouter>
    </div>
  );
}

export default App;
