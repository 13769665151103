import { useAuth0 } from "@auth0/auth0-react";

export default function CTA() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Klaar om te synchroniseren?</span>
          <span className="block">
            Meld je hier aan of contacteer ons voor meer info.
          </span>
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <a
              href="/login"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
              onClick={(e) => {
                e.preventDefault();
                loginWithRedirect();
              }}
            >
              Aanmelden
            </a>
          </div>
          <div className="ml-3 inline-flex">
            <a
              href="/contact"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200"
            >
              Neem contact op
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
