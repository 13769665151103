import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";
/* This example requires Tailwind CSS v2.0+ */
const validateCode = async (
  code: string,
  redirect_uri: string,
  token: string
) => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const uri = `https://teamleadersynapp.azurewebsites.net/api/StoreCredentials`;

        const metadataResponse = await fetch(uri, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            code,
            redirect_uri,
          }),
        });

        const tokenData = await metadataResponse.json();

        resolve(tokenData);
      } catch (e: any) {
        console.error(e.message);
        reject(e);
      }
    })();
  });
};

const getProfile = async (token: string): Promise<any | undefined> => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const uri = `https://teamleadersynapp.azurewebsites.net/api/GetExtendedProfile`;

        const metadataResponse = await fetch(uri, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await metadataResponse.json();

        resolve(data);
      } catch (e: any) {
        console.error(e.message);
        resolve(undefined);
      }
    })();
  });
};

interface IDatabaseState {
  dbHost?: string;
  dbPassword?: string;
  dbUser?: string;
  dbName?: string;
  dbPrefix?: string;
  dbPort?: string;
  valid?: boolean;
  error?: string;
}

interface IState {
  loading: boolean;
  profile?: any;
  database?: IDatabaseState;
  saveDB?: boolean;
}

const Teamleader: React.FC<{ redirectUri: string }> = ({ redirectUri }) => {
  const { user, getAccessTokenSilently } = useAuth0();

  const [state, setState] = useState<IState>({
    loading: true,
  });

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      let code = searchParams.get("code");
      const accessToken = await getAccessTokenSilently();

      if (accessToken) {
        setState((prevState) => ({
          ...prevState,
          loading: true,
        }));
        if (code) {
          let codeData = await validateCode(code, redirectUri, accessToken);

          console.log(codeData);
        }

        let profileData = await getProfile(accessToken);

        setState((prevState) => ({
          ...prevState,
          profile: profileData,
          database:
            profileData && profileData.database
              ? profileData.database
              : prevState.database,
          loading: false,
        }));
      }
    })();
  }, [searchParams, getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    console.log(state);
  }, [state]);

  const saveDBCredentials = async () => {
    if (state.database) {
      setState((prevState) => ({ ...prevState, saveDB: true }));
      let token = await getAccessTokenSilently();
      let dbCredentals: IDatabaseState | undefined;
      const { dbHost, dbName, dbPassword, dbPort, dbPrefix, dbUser } =
        state.database;
      const dbRes = await fetch(
        "https://teamleadersynapp.azurewebsites.net/api/StoreDBCredentials",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            database: {
              dbHost,
              dbName,
              dbPassword,
              dbPort,
              dbPrefix,
              dbUser,
            },
          }),
        }
      );

      const response = await dbRes.text();

      if (response) {
        try {
          let json = JSON.parse(response);
          if (json && json.dbCredentials) {
            dbCredentals = json.dbCredentials;
          }
        } catch (err) {
          console.error(err);
        }
      }

      setState((prevState) => ({
        ...prevState,
        saveDB: false,
        database: dbCredentals ? dbCredentals : {...prevState.database, error: response, valid: false},
      }));
    }
  };

  const startSync = async () => {
    if (state.database) {
      
      let token = await getAccessTokenSilently();
      
      const dbRes = await fetch(
        "https://teamleadersynapp.azurewebsites.net/api/SyncUser",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      const response = await dbRes.text();

      if (response) {
        console.log(response)
      }
    }
  };

  const handleChangeDB = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const { value, name } = target;

    if (name) {
      setState((prevState) => {
        let database: IDatabaseState = prevState.database
          ? prevState.database
          : {};

        // @ts-ignore
        database[name] = value;

        console.log(database);

        return {
          ...prevState,
          database
        }
      });
    }
  };

  const teamleaderURI = `https://focus.teamleader.eu/oauth2/authorize?redirect_uri=${redirectUri}&client_id=f3bf47f8d71d395c58e91e94510e4439&response_type=code`;

  return (
    <>
      <div>
        <p className="mt-2 mb-4 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Teamleader instellingen
        </p>
      </div>
      {state && state.loading && (
        <div className="flex justify-center py-3 min-w-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 animate-spin"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </div>
      )}
      {state && !state.loading && (
        <>
          <div className="bg-green-500">
            <div className="max-w-4xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-6">
              {(!state.profile ||
                (state.profile && !state.profile.teamleader)) && (
                <>
                  <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                    <span className="block">Laten we beginnen.</span>
                    <span className="block">
                      We moeten je Teamleader account nog koppelen.
                    </span>
                  </h2>
                  <p className="mt-4 text-lg leading-6 text-green-200">
                    Bij het koppelen zal je worden doorgestuurd naar de
                    Teamleader App om deze applicatie toegang te verlenen. Meld
                    je aan met een account van het bedrijf dat je wil
                    syncrhroniseren.
                  </p>
                  <a
                    href={teamleaderURI}
                    className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-green-50 sm:w-auto"
                  >
                    Teamleader koppelen
                  </a>
                </>
              )}
              {state.profile && state.profile.teamleader && (
                <>
                  <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                    <span className="block">
                      Je teamleader account is gekoppeld.
                    </span>
                  </h2>
                  <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-5">
                    <div className="px-4 py-5 sm:px-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Account informatie
                      </h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        Je persoonlijke gegevens
                      </p>
                    </div>
                    <div className="border-t border-gray-200">
                      <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Volledige naam
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {state.profile.teamleader.first_name}{" "}
                            {state.profile.teamleader.last_name}
                          </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Email
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {state.profile.teamleader.email}
                          </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Telefoon
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {state.profile.teamleader.telephones &&
                              state.profile.teamleader.telephones.map(
                                (item: any) => {
                                  return <div>{item.number}</div>;
                                }
                              )}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {state.profile && state.profile.teamleader && (
            <div className="bg-blue-500 mt-4">
              <div className="text-center py-16">
                <>
                  <div className="max-w-4xl mx-auto text-center px-4 sm:py-20 sm:px-6 lg:px-6">
                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                      <span className="block">
                        Om de synchronisatie te starten moeten we een database
                        toevoegen aan je acount.
                      </span>
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-green-200">
                      Vul hieronder de gegevens in van de MySQL database waarmee
                      je wil koppelen. (Deze database moet publiek beschikbaar
                      zijn en geen restricties hebben op basis van een IP adres)
                    </p>
                  </div>
                  <div>

                    {state && state.database && state.database.valid && (
                      <h3 className="text-1xl mb-4 font-extrabold text-white sm:text-1xl">Connectie met database successvol!</h3>
                    )}
                    {state && state.database && state.database.error && !state.database.valid && (
                      <h3 className="text-1xl mb-4 font-extrabold text-white sm:text-1xl text-red-200">{state.database.error}</h3>
                    )}
                  </div>
                  <div className="max-w-5xl mx-auto">
                    <div className="">
                      <div className="mt-5 md:mt-0 md:col-span-2">
                        <form action="#" method="POST">
                          <div className="shadow overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                              <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-8 sm:col-span-5">
                                  <label
                                    htmlFor="dbHost"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Database Host
                                  </label>
                                  <input
                                    type="text"
                                    name="dbHost"
                                    id="dbHost"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    onChange={handleChangeDB}
                                    value={
                                      state.database
                                        ? state.database.dbHost
                                        : undefined
                                    }
                                  />
                                </div>

                                <div className="col-span-4 sm:col-span-1">
                                  <label
                                    htmlFor="dbPort"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Port
                                  </label>
                                  <input
                                    type="text"
                                    name="dbPort"
                                    id="dbPort"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    onChange={handleChangeDB}
                                    value={
                                      state.database
                                        ? state.database.dbPort
                                        : undefined
                                    }
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="dbUser"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    User
                                  </label>
                                  <input
                                    type="text"
                                    name="dbUser"
                                    id="dbUser"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    onChange={handleChangeDB}
                                    value={
                                      state.database
                                        ? state.database.dbUser
                                        : undefined
                                    }
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="dbPassword"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Password
                                  </label>
                                  <input
                                    type="password"
                                    name="dbPassword"
                                    id="dbPassword"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    onChange={handleChangeDB}
                                    value={
                                      state.database
                                        ? state.database.dbPassword
                                        : undefined
                                    }
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="dbName"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Database
                                  </label>
                                  <input
                                    type="text"
                                    name="dbName"
                                    id="dbName"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    onChange={handleChangeDB}
                                    value={
                                      state.database
                                        ? state.database.dbName
                                        : undefined
                                    }
                                  />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="dbPrefix"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Table Prefix
                                  </label>
                                  <input
                                    type="text"
                                    name="dbPrefix"
                                    id="dbPrefix"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    onChange={handleChangeDB}
                                    placeholder="_teamleader_"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                              <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                onClick={(e) => {
                                  e.preventDefault();
                                  saveDBCredentials();
                                }}
                              >
                                Opslaan{" "}
                                {state && state.saveDB && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-6 w-6 animate-spin"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                      />
                                    </svg>
                                )}
                              </button>
                              <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ml-3"
                                onClick={(e) => {
                                  e.preventDefault();
                                  startSync();
                                }}
                              >
                                Start Sync
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Teamleader;
